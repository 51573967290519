import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, newDatas, vm, search) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        const allRowsHead = [
          [
            { text: vm.$t('globalTrans.division'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: search.division_id > 0 ? vm.getDivisionName(search.division_id) : vm.$t('globalTrans.all'), alignment: 'left', style: 'search' },
            { text: vm.$t('globalTrans.district'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: search.district_id > 0 ? vm.getDistrictName(search.district_id) : vm.$t('globalTrans.all'), alignment: 'left', style: 'search' },
            { text: vm.$t('crop_price_config.price_date'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: dateFormat(search.price_date), alignment: 'left', style: 'search' }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['10%', '1%', '15%', '10%', '1%', '15%', '15%', '1%', '15%'],
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        pdfContent.push({ text: '', style: 'fertilizer' })
        const allRows = [
          [
            { text: vm.$t('globalTrans.district'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.upazila'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('crop_price_config.price_entry_new'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('publication_management.published_status'), style: 'th', alignment: 'center', bold: true }
          ]
        ]
        Object.values(newDatas).forEach(info => {
            info.forEach((info2, index2) => {
                allRows.push([
                    { text: vm.getDistrictName(info2.district_id), alignment: 'center', style: 'td' },
                    { text: vm.getUpazilaName(info2.upazila_id), alignment: 'center', style: 'td' },
                    { text: info2.price_date ? vm.$t('globalTrans.yes') : vm.$t('globalTrans.no'), alignment: 'center', style: 'td' },
                    { text: info2.approve_status ? vm.$t('globalTrans.yes') : vm.$t('globalTrans.no'), alignment: 'center', style: 'td' }
                ])
            })
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['25%', '25%', '25%', '25%'],
            body: allRows
          }
        })
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('price-entry-pending-report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
